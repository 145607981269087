import React from "react"
import styled from "styled-components"

const StyledSectionHeading = styled.div`
  max-width: 600px;
  text-align: center;
  margin: 0 auto 60px auto;
`

const StyledSectionTitle = styled.h4`
  color: #f9416c;
  font-size: 30px;
  font-weight: 500;
  line-height: 110%;
  letter-spacing: -0.04em;
`

const StyledSectionSubHeading = styled.p`
  color: #460413;
  font-size: 19px;
  max-width: 540px;
  line-height: 116%;
  letter-spacing: -0.04em;
  margin: 10px auto 0 auto;
`

const SectionHeading = ({ title, children }) => {
  return (
    <StyledSectionHeading>
      <StyledSectionTitle>{title}</StyledSectionTitle>
      <StyledSectionSubHeading>{children}</StyledSectionSubHeading>
    </StyledSectionHeading>
  )
}

export default SectionHeading
