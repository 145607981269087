import React from "react"
import styled from "styled-components"
import { Twitter, Instagram, Facebook } from "react-feather"

const StyledFooter = styled.div`
  margin: 0 auto;
  margin-top: 80px;
  max-width: 1100px;
  padding-top: 60px;
  padding-bottom: 80px;
  border-top: 1px solid #f3f3f3;
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media (max-width: 800px) {
    display: block;
    text-align: center;
  }

  nav {
    @media (max-width: 800px) {
      margin-bottom: 30px;
    }
  }

  nav li {
    margin-right: 24px;
    display: inline-block;

    @media (max-width: 800px) {
      display: block;
      margin-right: 0;
      margin-bottom: 8px;
    }
  }

  nav a,
  nav span {
    color: #82727b;
    font-size: 15px;

    @media (max-width: 800px) {
      display: block;
    }
  }

  a {
    text-decoration: none;

    &:hover {
      color: #52404b;
    }
  }

  .social li {
    display: inline-block;

    a {
      color: #ff517a;
      margin-left: 8px;

      &:hover {
        color: #bb002c;
      }
    }

    svg {
      width: 20px;
      height: 20px;
    }
  }
`

const Footer = () => {
  return (
    <StyledFooter>
      <nav>
        <ul>
          <li>
            <a href="/#kenya-2020">Kenya 2020</a>
          </li>
          <li>
            <a href="/#period-poverty">Period Poverty</a>
          </li>
          <li>
            <a href="/cup">Menstrual Cup</a>
          </li>
          <li>
            <a href="mailto:hello@sondercharity.com">Contact Us</a>
          </li>
          <li>
            <span>© 2020 Sonder</span>
          </li>
        </ul>
      </nav>

      <ul className="social">
        <li>
          <a href="https://twitter.com/sonder_charity">
            <Twitter />
          </a>
        </li>
      </ul>
    </StyledFooter>
  )
}

export default Footer
