import React from "react"
import styled from "styled-components"
import blob1 from "./blobs/1.svg"
import blob2 from "./blobs/2.svg"
import blob3 from "./blobs/3.svg"

const BLOBS = [blob1, blob2, blob3]

const StyledThreeColumns = styled.h1`
  display: flex;
  flex-wrap: wrap;
  margin-left: -25px;
  margin-right: -25px;
  margin-bottom: 100px;
`

const StyledThreeColumnsColumn = styled.div`
  width: 33%;
  padding: 0 25px;
  text-align: center;

  @media (max-width: 800px) {
    width: 100%;
    margin-bottom: 30px;
  }
`

const StyledThreeColumnsHeading = styled.h5`
  font-weight: 600;
  margin-bottom: 8px;
`

const StyledThreeColumnsText = styled.p`
  color: #430028;
  font-size: 16px;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: -0.02em;
`

const StyledThreeColumnsTitleContainer = styled.div`
  width: 110px;
  height: 110px;
  display: flex;
  color: #bb1d42;
  font-size: 24px;
  font-weight: 600;
  align-items: center;
  justify-content: center;
  margin: 0 auto 20px auto;
  background: url(${props => props.blob});
`

const ThreeColumns = ({ columns }) => {
  return (
    <StyledThreeColumns>
      {columns.map((column, i) => (
        <StyledThreeColumnsColumn key={column.title}>
          <StyledThreeColumnsTitleContainer
            blob={BLOBS[column.blob - 1 || i || 0]}
          >
            {column.title}
          </StyledThreeColumnsTitleContainer>
          <StyledThreeColumnsHeading>
            {column.heading}
          </StyledThreeColumnsHeading>
          <StyledThreeColumnsText>{column.content}</StyledThreeColumnsText>
        </StyledThreeColumnsColumn>
      ))}
    </StyledThreeColumns>
  )
}

export default ThreeColumns
