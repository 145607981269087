import React from "react"
import styled from "styled-components"
import clock from "./clock.svg"

const StyledPageDiver = styled.div`
  padding-top: 40px;
  text-align: center;
  position: relative;
  padding-bottom: 80px;
  letter-spacing: -0.02em;
  border-top: 2px dashed #ffc8d5;

  &::before {
    left: 50%;
    top: -27px;
    content: "";
    width: 50px;
    height: 50px;
    display: block;
    margin-left: -25px;
    border-radius: 50%;
    position: absolute;
    background-color: #ffe8ee;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(${clock});
  }
`

const StyledPageDiverContent = styled.div`
  margin: 0 auto;
  max-width: 320px;
  font-size: 17 px;
  color: #7c001d;
`

const PageDivider = ({ children }) => {
  return (
    <StyledPageDiver>
      <StyledPageDiverContent>{children}</StyledPageDiverContent>
    </StyledPageDiver>
  )
}

export default PageDivider
