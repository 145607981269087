import React from "react"
import styled from "styled-components"
import Container from "../Container"
import wave from "./wave.svg"

const StyledHero = styled.div`
  height: 500px;
  display: flex;
  color: #8d4858;
  position: relative;
  align-items: center;
  margin-bottom: 100px;
  justify-content: left;
  background-size: cover;
  background-position: center;

  &::before {
    left: 0;
    right: 0;
    bottom: -1px;
    content: "";
    height: 40px;
    position: absolute;
    pointer-events: none;
    background: url(${wave}) center top no-repeat;
    background-size: 100% 100%;
  }
`

const StyledHeading = styled.h1`
  width: 55%;
  color: #cc4162;
  font-size: 46px;
  margin-top: 40px;
  margin-bottom: 20px;
  letter-spacing: -0.1rem;
`

const StyledSubHeading = styled.p`
  width: 50%;
  font-size: 20px;
  font-weight: 400;
  line-height: 26px;
  margin-bottom: 50px;
`

const PageHeader = ({ header, subHeader, background }) => (
  <StyledHero
    css={`
      background-image: url(${background});
    `}
  >
    <Container>
      <StyledHeading>{header}</StyledHeading>
      <StyledSubHeading>{subHeader}</StyledSubHeading>
    </Container>
  </StyledHero>
)

export default PageHeader
