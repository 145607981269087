import React from "react"
import styled from "styled-components"
import Logo from "../Logo"

const StyledHeader = styled.header`
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  padding: 40px 30px;
  position: absolute;
`

const StyledHeaderLogoAnchor = styled.a``

const StyledHeaderContainer = styled.div`
  margin: 0 auto;
  display: flex;
  max-width: 1100px;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 800px) {
    justify-content: center;
  }
`

const Nav = styled.nav`
  margin-right: -12px;

  a {
    padding: 12px;
    font-size: 17px;
    font-weight: 500;
    margin-left: 16px;
    text-decoration: none;
    letter-spacing: -0.01rem;
    color: rgba(255, 255, 255, 0.9);

    &:hover {
      color: rgba(255, 255, 255, 1);
    }
  }

  @media (max-width: 800px) {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: none;
    position: fixed;
    background: rgba(0, 0, 0, 0.7);
  }
`

const Header = () => (
  <StyledHeader>
    <StyledHeaderContainer>
      <StyledHeaderLogoAnchor href="/">
        <Logo />
      </StyledHeaderLogoAnchor>
      <Nav>
        <a href="/#kenya-2020">Kenya 2020</a>
        <a href="/#period-poverty">Period Poverty</a>
        <a href="/cup">Menstrual Cup</a>
        <a href="mailto:hello@sondercharity.com">Contact Us</a>
      </Nav>
    </StyledHeaderContainer>
  </StyledHeader>
)

export default Header
