import React from "react"
import styled from "styled-components"

export const StyledButton = styled.button`
  height: 50px;
  color: white;
  padding: 0 40px;
  font-size: 20px;
  font-weight: 600;
  border-radius: 30px;
  background: #f9416c;
  align-items: center;
  display: inline-flex;
  text-decoration: none;
  border: none;
  outline: none;

  &:hover {
    background-color: #cb1d46;
  }
`

export default StyledButton
