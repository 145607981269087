import React from "react"
import styled from "styled-components"
import blob from "./blob.svg"

const StyledIllustratedContent = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  margin-bottom: 60px;
  padding-bottom: 60px;
`

const StyledIllustratedIllustration = styled.div.attrs(props => ({
  style: {
    backgroundImage: `url(${props.image})`,
  },
}))`
  width: 45%;
  height: 250px;
  border-radius: 12px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
`

const StyledIllustratedInner = styled.div`
  width: 55%;
  color: #430028;
  font-size: 16px;
  line-height: 110%;
  position: relative;
  letter-spacing: -0.02em;
  padding-left: ${props => props.align === "left" && "80px"};
  padding-right: ${props => props.align === "right" && "80px"};

  p {
    margin-bottom: 16px;
  }

  li {
    list-style: disc;
    margin-bottom: 4px;
    margin-left: 16px;
  }
`

const StyledNumber = styled.div`
  width: 70px;
  height: 70px;
  display: flex;
  color: #bb1d42;
  font-size: 24px;
  font-weight: 600;
  margin-left: -16px;
  margin-bottom: 20px;
  align-items: center;
  justify-content: center;
  background: url(${blob});
  background-size: cover;
`

const StyledIllustratedHeading = styled.h3`
  color: #430028;
  font-size: 17px;
  font-weight: 600;
  line-height: 110%;
  margin-bottom: 8px;
  letter-spacing: -0.02em;
`

const StyledIllustratedConnection = styled.svg`
  bottom: ${props => (props.align === "left" ? "-160px" : "-140px")};
  position: absolute;
  left: ${props => props.align === "left" && "-400px"};
  right: ${props => props.align === "right" && "10px"};
`

const IllustratedContent = ({
  image,
  number,
  heading,
  children,
  align,
  showConnection,
}) => {
  const content = (
    <StyledIllustratedInner align={align}>
      {number && <StyledNumber>{number}</StyledNumber>}
      {heading && (
        <StyledIllustratedHeading>{heading}</StyledIllustratedHeading>
      )}
      {children}

      {showConnection && (
        <StyledIllustratedConnection
          xmlns="http://www.w3.org/2000/svg"
          width="486"
          height="124"
          fill="none"
          viewBox="0 0 486 124"
          align={align}
        >
          {align === "left" ? (
            <path
              stroke="#FFC8D5"
              strokeDasharray="8 8"
              strokeWidth="2"
              d="M485 0c0 38-63.232 99.97-207 81C134 62 65 39 1 123"
            ></path>
          ) : (
            <path
              stroke="#FFC8D5"
              strokeDasharray="8 8"
              strokeWidth="2"
              d="M1 0c0 39.35 55.71 102.095 250.187 70.19C445.665 38.287 456.806 90.398 472 134"
            ></path>
          )}
        </StyledIllustratedConnection>
      )}
    </StyledIllustratedInner>
  )

  return (
    <StyledIllustratedContent align={align}>
      {align === "left" ? (
        <>
          <StyledIllustratedIllustration image={image} />
          {content}
        </>
      ) : (
        <>
          {content}
          <StyledIllustratedIllustration image={image} />
        </>
      )}
    </StyledIllustratedContent>
  )
}

IllustratedContent.defaultProps = {
  align: "left",
  showConnection: true,
}

export default IllustratedContent
