import React from "react"
import styled from "styled-components"

const SIZES = {
  l: "1100px",
  m: "1000px",
}

export const StyledContainer = styled.div`
  width: 94%;
  margin: 0 auto;
  max-width: ${props => SIZES[props.size || "l"]};
`

const Container = ({ size, children }) => (
  <StyledContainer size={size}>{children}</StyledContainer>
)

export default Container
