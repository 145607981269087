import React from "react"
import { createGlobalStyle } from "styled-components"
import Header from "../Header"
import Footer from "../Footer"

import m100woff from "../../fonts/metropolis/WOFF/Metropolis-Thin.woff"
import m100woff2 from "../../fonts/metropolis/WOFF2/Metropolis-Thin.woff2"
import m200woff from "../../fonts/metropolis/WOFF/Metropolis-ExtraLight.woff"
import m200woff2 from "../../fonts/metropolis/WOFF2/Metropolis-ExtraLight.woff2"
import m300woff from "../../fonts/metropolis/WOFF/Metropolis-Light.woff"
import m300woff2 from "../../fonts/metropolis/WOFF2/Metropolis-Light.woff2"
import m400woff from "../../fonts/metropolis/WOFF/Metropolis-Regular.woff"
import m400woff2 from "../../fonts/metropolis/WOFF2/Metropolis-Regular.woff2"
import m500woff from "../../fonts/metropolis/WOFF/Metropolis-Medium.woff"
import m500woff2 from "../../fonts/metropolis/WOFF2/Metropolis-Medium.woff2"
import m600woff from "../../fonts/metropolis/WOFF/Metropolis-SemiBold.woff"
import m600woff2 from "../../fonts/metropolis/WOFF2/Metropolis-SemiBold.woff2"
import m700woff from "../../fonts/metropolis/WOFF/Metropolis-Bold.woff"
import m700woff2 from "../../fonts/metropolis/WOFF2/Metropolis-Bold.woff2"
import m800woff from "../../fonts/metropolis/WOFF/Metropolis-ExtraBold.woff"
import m800woff2 from "../../fonts/metropolis/WOFF2/Metropolis-ExtraBold.woff2"
import m900woff from "../../fonts/metropolis/WOFF/Metropolis-Black.woff"
import m900woff2 from "../../fonts/metropolis/WOFF2/Metropolis-Black.woff2"

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: "metropolis";
    font-weight: 100;
    src: url(${m100woff2}) format('woff2'),
         url(${m100woff}) format('woff');
  }

  @font-face {
    font-family: "metropolis";
    font-weight: 200;
    src: url(${m200woff2}) format('woff2'),
         url(${m200woff}) format('woff');
  }

  @font-face {
    font-family: "metropolis";
    font-weight: 300;
    src: url(${m300woff2}) format('woff2'),
         url(${m300woff}) format('woff');
  }

  @font-face {
    font-family: "metropolis";
    font-weight: 400;
    src: url(${m400woff2}) format('woff2'),
         url(${m400woff}) format('woff');
  }

  @font-face {
    font-family: "metropolis";
    font-weight: 400;
    src: url(${m400woff2}) format('woff2'),
         url(${m400woff}) format('woff');
  }

  @font-face {
    font-family: "metropolis";
    font-weight: 500;
    src: url(${m500woff2}) format('woff2'),
         url(${m500woff}) format('woff');
  }

  @font-face {
    font-family: "metropolis";
    font-weight: 600;
    src: url(${m600woff2}) format('woff2'),
         url(${m600woff}) format('woff');
  }

  @font-face {
    font-family: "metropolis";
    font-weight: 700;
    src: url(${m700woff2}) format('woff2'),
         url(${m700woff}) format('woff');
  }

  @font-face {
    font-family: "metropolis";
    font-weight: 800;
    src: url(${m800woff2}) format('woff2'),
         url(${m800woff}) format('woff');
  }

  @font-face {
    font-family: "metropolis";
    font-weight: 900;
    src: url(${m900woff2}) format('woff2'),
         url(${m900woff}) format('woff');
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: metropolis, sans-serif;
  }

  html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
`

export default ({ children }) => {
  const scrollTo = el => {
    if (el) {
      window.scroll({
        top: el.offsetTop,
        behavior: "smooth",
      })
    }
  }

  const handleClick = e => {
    if (e.target.href && /#.*/.test(e.target.href)) {
      const id = e.target.href.split("#")[1]
      const el = document.querySelector(`#${id}`)
      if (el) {
        e.preventDefault()
        scrollTo(el)
      }
    }
  }

  React.useEffect(() => {
    const hash = window.location.hash
    if (hash) {
      const el = document.querySelector(hash)
      scrollTo(el)
    }

    document.addEventListener("click", handleClick)
  }, [scrollTo])

  return (
    <>
      <GlobalStyle />
      <Header />
      {children}
      <Footer />
    </>
  )
}
